/* eslint-disable react/no-danger */
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import ImportContactsIcon from '@material-ui/icons/ImportContacts'
import PublicIcon from '@material-ui/icons/Public'
import EventNoteIcon from '@material-ui/icons/EventNote'
import DateRangeIcon from '@material-ui/icons/DateRange'
import LanguageIcon from '@material-ui/icons/Language'
import AttachmentIcon from '@material-ui/icons/Attachment'
import AirplanemodeActiveIcon from '@material-ui/icons/AirplanemodeActive'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import LabelIcon from '@material-ui/icons/Label'
import CircularProgress from '@material-ui/core/CircularProgress'
import GLOBAL from '../Global'
import TourMap from './TourMap'
import SlickSlider from '../components/Slider'

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell)

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow)

class TourSingle extends Component {
  static setTitle(title) {
    document.title = `${title} | TOUR | ${GLOBAL.SITENAME}`
  }

  setName = e => {
    switch (e) {
      case 'midkniteeuro':
        return 'MID KNITE €URO'
      case 'midkniteusa':
        return 'MID KNITE U$A'
      case 'midkniteasia':
        return 'MID KNITE ASIA'
      case 'midknitepacific':
        return 'MID KNITE PACIFIC'
      case 'midknitelatin':
        return 'MID KNITE LATIN'
      case 'midkniteafrica':
        return 'MID KNITE AFRICA'
      case 'midkniterouge':
          return 'MID KNITE ROUGE'
      case 'midknitejapan':
        return 'MID KNITE JAPAN'
      case 'midkniteblue':
        return 'MID KNITE BLUE'
      case 'bleudetokyo':
        return 'BLEU DE TOKYO'
      case 'midknitetokyo':
        return 'MID KNITE TOKYO'
      case 'midknitemetro':
          return 'MID KNITE METRO'
      case 'midknitegreen':
        return 'MID KNITE GREEN'
      case 'midkniteyou':
        return 'MID KNITE YOU'
      case 'midkniteaqua':
          return 'MID KNITE AQUA'
      case 'midkniteozone':
        return 'MID KNITE OZONE'
      case 'midkniteone':
        return 'MID KNITE ONE'
      case 'midknitecentral':
        return 'MID KNITE CENTRAL'
      case 'midkniteviolet':
        return 'MID KNITE VIOLET'
      case 'midknitediamond':
        return 'MID KNITE DIAMOND'
      case 'midknitemichinok':
        return 'MID KNITE MICHINOK'
      case 'midknitesunrise':
        return 'MID KNITE SUNRISE'
      default:
        return null
    }
  }

  render() {
    const {
      props: { category, data, history },
    } = this
    if (!data)
      return (
        <div className="nowLoading">
          <CircularProgress size="6rem" />
        </div>
      )
    TourSingle.setTitle(data.title)
    const mainVisuals = data.mainVisualUrl
    let date = ''
    if (data.date.end) {
      date = `${data.date.start}〜${data.date.end}`
    } else {
      date = data.date.start
    }
    let parentCategory = ''
    if (data.parentCategory[0] === 'event') {
      parentCategory = 'EVENT '
    } else {
      parentCategory = 'TOUR '
    }
    return (
      <div className={`single ${category}`}>
        <Button onClick={() => history.goBack()} className="backButton">
          <ArrowBackIosIcon />
          BACK
        </Button>
        <h1 dangerouslySetInnerHTML={{ __html: data.title }} />
        <div className="tagContainer">
          {data.areaCategory.map(e => (
            <span key={e} className={`tag ${e}`}>
              {this.setName(e)}
            </span>
          ))}
        </div>
        <div className="tagContainer">
          {data.countryCategory.map(e => (
            <span key={e} className={`tag ${e.toLowerCase()}`}>
              {e}
            </span>
          ))}
        </div>
        {mainVisuals !== '' && (
          <div className="mainVisual">
            <SlickSlider
              images={mainVisuals}
              category="tour"
              maxWidth="400"
              captions={data.caption}
            />
          </div>
        )}

        <div
          className="infoText"
          dangerouslySetInnerHTML={{ __html: data.text }}
        />
        <h2 className="heading02">
          <ImportContactsIcon />
          {parentCategory}
          {GLOBAL.TOUR.INFORMATION}
        </h2>
        <Paper className="info">
          <h3 className="heading03">
            <DateRangeIcon />
            {GLOBAL.TOUR.SCHEDULE.DATE}
          </h3>
          <div className="date">{date}</div>
          <h3 className="heading03">
            <LanguageIcon />
            {GLOBAL.TOUR.SCHEDULE.COUNTRY}
          </h3>
          <div className="country">
            {data.countryCategory.map(e => (
              <span key={e} className="countryName">
                <img
                  src={`/images/flags/${e
                    .toLowerCase()
                    .replace(/\s+/g, '')}.png`}
                  alt={e}
                />
                {e}
              </span>
            ))}
          </div>
          {data.cooperations && (
            <>
              <h3 className="heading03">
                <AirplanemodeActiveIcon />
                {GLOBAL.TOUR.COOPERATIONS}
              </h3>
              <div className="coperations">{data.cooperations}</div>
            </>
          )}
          {data.note && (
            <>
              <h3 className="heading03">
                <AttachmentIcon />
                {GLOBAL.TOUR.NOTE}
              </h3>
              <div className="note">{data.note}</div>
            </>
          )}
          {data.tourId && (
            <>
              <h3 className="heading03">
                <LabelIcon />
                TOUR ID
              </h3>
              <div className="note">{data.tourId}</div>
            </>
          )}
        </Paper>
        {data.map && (
          <>
            <h2 className="heading02">
              <PublicIcon />
              {GLOBAL.TOUR.ROUTEMAP}
            </h2>
            <TourMap data={data.map} />
          </>
        )}
        {data.schedule && (
          <>
            <h2 className="heading02">
              <EventNoteIcon />
              {parentCategory}
              {GLOBAL.TOUR.SCHEDULE_TITLE}
            </h2>
            <TableContainer className="scheduleTable" component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      {GLOBAL.TOUR.SCHEDULE.DATE}
                    </StyledTableCell>
                    <StyledTableCell>
                      {GLOBAL.TOUR.SCHEDULE.CITY}
                    </StyledTableCell>
                    <StyledTableCell>
                      {GLOBAL.TOUR.SCHEDULE.COUNTRY}
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.schedule.map((e,i) => (
                    <StyledTableRow key={i}>
                      <StyledTableCell component="th" scope="row">
                        {e.date}
                      </StyledTableCell>
                      <StyledTableCell>{e.city}</StyledTableCell>
                      <StyledTableCell>
                        {Array.isArray(e.country) &&
                          e.country.map(el => (
                            <div className="countryCell">
                              <img
                                src={`/images/flags/${el
                                  .toLowerCase()
                                  .replace(/\s+/g, '')}.png`}
                                alt={el}
                                className="scheduleFlag"
                              />
                              {el}
                            </div>
                          ))}
                        {!Array.isArray(e.country) && (
                          <div className="countryCell">
                            <img
                              src={`/images/flags/${e.country
                                .toLowerCase()
                                .replace(/\s+/g, '')}.png`}
                              alt={e.country}
                              className="scheduleFlag"
                            />
                            {e.country}
                          </div>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {/* <h2 className="heading02">
          <HmkIcon />
          {GLOBAL.TOUR.FROM_HUMANKIND_TITLE}
        </h2>
        <Paper className="info">MESSAGE FROM HUMANKIND HERE</Paper> */}
      </div>
    )
  }
}

export default withRouter(TourSingle)

TourSingle.propTypes = {
  data: PropTypes.object,
  category: PropTypes.string,
  history: PropTypes.object.isRequired,
}

TourSingle.defaultProps = {
  data: null,
  category: null,
}
